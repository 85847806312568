import { useEffect } from "react";
import { BillingRequestFlowResource } from "@gocardless/api/dashboard/types";
import { useBillingRequestFlowResume } from "@gocardless/api/dashboard/billing-request-flow";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import {
  Box,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  FontWeight,
  Form,
  Glyph,
  JustifyItems,
  Space,
  Text,
  XYGrid,
} from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { useForm } from "react-hook-form";
import { useSegment } from "src/shared/Segment/useSegment";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { TrackingEvents } from "src/common/trackingEvents";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import CustomerField from "src/components/pages/CollectCustomerDetails/CustomerDetailsForm/CustomerField";
import { ErrorType, PayerThemeType } from "src/state";
import { ErrorTypeEnum } from "src/state/errors";
import { setItem } from "src/legacy-ui/local-storage";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { HTTPError } from "@gocardless/api/utils/api";

interface EmailVerificationViewProps {
  billingRequestFlow: BillingRequestFlowResource;
  payerTheme?: PayerThemeType;
  setShowEmailVerification: (requiresEmailVerification: boolean) => void;
  setError: (error?: ErrorType) => void;
}

const EmailVerificationView = ({
  billingRequestFlow,
  payerTheme,
  setShowEmailVerification,
  setError,
}: EmailVerificationViewProps) => {
  const { sendEvent, sendEventAndRedirect } = useSegment();

  const { errors, register, handleSubmit, getValues } = useForm<{
    email: string;
  }>({
    mode: "onBlur",
  });

  const creditorName =
    billingRequestFlow?.config?.merchant_contact_details?.name;

  const [resume] = useBillingRequestFlowResume(billingRequestFlow.id, {
    onSuccess: async (res) => {
      if (res?.authorisation_url) {
        const pageRedirect = () =>
          res.authorisation_url && redirectToNewAuthLink(res.authorisation_url);
        // the submitted email doesn't match the assigned customer
        // hence redirecting to a new BRF
        sendEventAndRedirect(
          TrackingEvents.EMAIL_VERIFICATION_NEW_FLOW_GENERATED,
          pageRedirect
        );
      } else {
        // the submitted email matches the assigned customer hence
        // proceeding with the current BRF
        sendEvent(TrackingEvents.EMAIL_VERIFICATION_FLOW_RESUMED);
        setShowEmailVerification(false);
      }
    },
    onError: async (e) => {
      const httpError = e as HTTPError;
      const responseErrors =
        httpError.response?.body &&
        (await getErrorsFromErrorResponse(httpError));

      const responseErrorReason = responseErrors && responseErrors[0]?.reason;
      if (httpError?.response?.status === HTTPStatusCode.Unauthorized) {
        if (responseErrorReason === "missing_template") {
          sendEvent(TrackingEvents.EMAIL_VERIFICATION_UNAUTHORISED);
          setError({
            errorType: ErrorTypeEnum.ResumeUnauthorisedError,
          });
        } else {
          setError({ errorType: ErrorTypeEnum.LinkExpired });
        }
      } else {
        sendEvent(TrackingEvents.EMAIL_VERIFICATION_FAILED);
        setError({ errorType: ErrorTypeEnum.GenericError });
      }
    },
  });

  const redirectToNewAuthLink = (authLink: string) => {
    const { email: submittedEmail } = getValues();
    const authUrl = new URL(authLink);
    const brfId = authUrl.searchParams?.get("id");
    if (brfId) {
      // constructing a key based on the new BRF id
      const submittedEmailStorageKey = `${brfId}-prefill-email`;
      // save the customer's email in local storage.
      // it is used on the collect-customer-details form to prefill the email field
      setItem(submittedEmailStorageKey, submittedEmail);
    }
    // force re-direct to the new authorisation link.
    // to make sure that a session is initialised for the associated BRF
    window.location.replace(authLink);
  };

  const onSubmit = (form: { email: string }) => {
    sendEvent(TrackingEvents.EMAIL_VERIFICATION_FORM_SUBMITTED);
    resume(form);
  };

  // register in Segment that Email Verification was shown
  useEffect(() => {
    sendEvent(TrackingEvents.EMAIL_VERIFICATION_VIEWED);
  }, []);

  return (
    <>
      <Box className="fs-unmask">
        <Text size={[5, 6]}>
          <Trans id="billing-request.heading.base">
            <Text weight={FontWeight.SemiBold}>
              Verify your email to set up this payment with
            </Text>{" "}
            <Text weight={FontWeight.Normal}>{creditorName}</Text>
          </Trans>
        </Text>
      </Box>
      <Space v={1.5} />
      <Form onSubmit={handleSubmit((form) => onSubmit(form))} noValidate>
        <CustomerField
          fieldName="email"
          labelName={i18n._(
            t({
              id: "email-verification.email.label",
              message: "Email address",
            })
          )}
          errors={errors}
          register={register}
          type="email"
          i18n={i18n}
          requiredField
        />

        <Space v={2} />
        <XYGrid rowGap={1} justifyItems={JustifyItems.Start}>
          <BrandedButton
            backgroundColor={getBrandColorFor(
              BrandedComponentType.Button,
              payerTheme
            )}
            rightIcon={Glyph.ArrowForward}
            type="submit"
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            variant={ButtonVariant.PrimaryOnLight}
            data-testid="email-verification-input-submit-btn"
          >
            <Trans id="email-verification.continue-button">Continue</Trans>
          </BrandedButton>
        </XYGrid>
      </Form>
    </>
  );
};

export default EmailVerificationView;
