import { useContext, ReactNode } from "react";
import { useDecision } from "@optimizely/react-sdk";
import {
  OptimizelyFlag,
  OptimizelyVariant,
} from "src/shared/Optimizely/constants";
import { GlobalState } from "src/state";
import { BillingRequestFlowResource } from "@gocardless/api/dashboard/types";

import EmailVerificationBoundaryView from "./EmailVerificationBoundaryView";

const EmailVerification = ({ children }: { children: ReactNode }) => {
  const {
    billingRequestFlow,
    payerTheme,
    showEmailVerification,
    setShowEmailVerification,
    setError,
  } = useContext(GlobalState);

  /* TODO: remove the feature flag after the full rollout */
  const [emailVerificationDecision] = useDecision(
    OptimizelyFlag.EMAIL_VERIFICATION
  );

  const emailVerificationEnabled =
    emailVerificationDecision.variationKey === OptimizelyVariant.FLAG_ON;

  if (emailVerificationEnabled && showEmailVerification) {
    return (
      <EmailVerificationBoundaryView
        billingRequestFlow={billingRequestFlow as BillingRequestFlowResource}
        payerTheme={payerTheme}
        setShowEmailVerification={setShowEmailVerification}
        setError={setError}
      />
    );
  }

  return <>{children}</>;
};

export default EmailVerification;
