import {
  Box,
  Color,
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  Option,
  Select,
  Text,
} from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { FormContextValues, NestDataObject } from "react-hook-form";
import { USRegion, usRegionNames } from "src/common/country";
import {
  CustomerDetailsObject,
  getRequiredErrorTranslated,
} from "src/config/customer-details/customer-details-config";

export interface CustomerFieldProps {
  fieldName: keyof CustomerDetailsObject;
  labelName: string;
  errors: NestDataObject<CustomerDetailsObject>;
  register: FormContextValues["register"];
  defaultValue?: string | null;
  requiredField?: boolean;
  options?: Object;
  description?: string;
  gridArea?: string;
  type?: string;
  i18n: I18n;
}

export const CustomerField = ({
  fieldName,
  labelName,
  errors,
  register,
  defaultValue,
  requiredField = true,
  options,
  description,
  gridArea = "",
  type,
  i18n,
}: CustomerFieldProps) => {
  const error = errors[fieldName];
  const translatedRequiredFieldText = getRequiredErrorTranslated(fieldName);
  return (
    <Box gridArea={gridArea}>
      <Field>
        <Label htmlFor={fieldName}>
          {labelName}{" "}
          {!requiredField && (
            <Text color={Color.Greystone_1000} size={2}>
              <Trans id="collect-customer-details-page.optional-field">
                (optional)
              </Trans>
            </Text>
          )}
        </Label>
        {description && <Hint>{description}</Hint>}
        {options && (
          <Select
            defaultValue={defaultValue ?? undefined}
            name={fieldName}
            id={fieldName}
            ref={register({ required: true })}
          >
            {Object.keys(options).map((option) => {
              let label = option;

              // For US Regions we want to translate to a more readable name
              if (fieldName === "region" && i18n) {
                label = usRegionNames(i18n)[option as USRegion];
              }
              return (
                <Option key={option} value={option}>
                  {label}
                </Option>
              );
            })}
          </Select>
        )}
        {!options && (
          <Input
            name={fieldName}
            id={fieldName}
            defaultValue={defaultValue || ""}
            ref={register({
              required: requiredField && translatedRequiredFieldText,
              pattern:
                type === "email"
                  ? {
                      value: /.+@.+\..+/,
                      message: i18n._(
                        t({
                          id: "collect-customer-details-page.email.invalid-error-message",
                          message: "Enter a valid email",
                        })
                      ), // permissive client-side validation
                    }
                  : undefined,
            })}
            status={error ? FormFieldStatus.Danger : undefined}
            type={type}
          />
        )}
        {error && (
          <Hint status={FormFieldStatus.Danger}>
            {["required", "invalid", "pattern"].includes(error.type) &&
              error.message}
          </Hint>
        )}
      </Field>
    </Box>
  );
};

export default CustomerField;
